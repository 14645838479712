<template>
  <!-- 目标管理>目标列表 -->
  <div class="dict">
    <!-- 表单 -->
    <div class="form-header-btn btn1" >
      <div class="title">目标列表</div>
      <div>
        <el-button type="primary" @click="$router.push('/createTarget')" v-if="isAdd"  >新增</el-button>
      </div>
    </div>
    <el-form :model="form" onsubmit="return false" :inline="true" class="search-form">
      <!-- <el-col :span="19" style="display: flex"> -->
        <el-form-item label="">
          <el-select v-model="form.targetType" placeholder="请选择目标类型">
            <el-option
              v-for="(item, index) in dictList.target_type"
              :key="index"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-input v-model="form.targetName" placeholder="请输入目标名称" />
        </el-form-item>
        <el-form-item label="">
          <!-- {{form.deptLabel}} -->
          <el-cascader filterable  placeholder="请选择责任部门"
            :options="departList"
            :props="deptProps"
            v-model="form.deptLabel"
            :show-all-levels="false"
            clearable></el-cascader>
          <!-- <el-select v-model="form.deptLabel" placeholder="请选择责任部门">
            <el-option :label="item.name" v-for="(item,index) in departList" :key="index" :value="item.label"></el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item label="">
          <el-select v-model="form.status" placeholder="请选择目标状态">
            <el-option
              v-for="(item, index) in dictList.target_status"
              :key="index"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="searchFun"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button @click="resetFun" icon="el-icon-refresh-right" class="reset">重置</el-button>
        </el-form-item>
      <!-- </el-col> -->
        
      <!-- <el-form-item style="float: right">
        <el-button type="primary" @click="$router.push('/createTarget')" v-if="isAdd"
          >新增</el-button
        >
      </el-form-item> -->
    </el-form>
    <l-table
      :columns="columns"
      :dataSource="tableData"
      :pagination="pageFlag"
      :ipagination="page"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :loading="tableLoading"
      :showXh="true"
      :height="'calc(100% - 1.77083vw)'"
      :autoHeight="false"
    >
      <!-- <el-table-column
        prop="type"
        label="序号"
        align="center"
        slot="type"
      ></el-table-column> -->
      <el-table-column
        prop="targetName"
        label="目标名称"
        align="center"
        min-width="150"
        slot="targetName"
      >
      </el-table-column>
      <el-table-column
        prop="targetType"
        label="目标类型"
        min-width="150"
        align="center"
        slot="targetType"
        ><template slot-scope="scope">
          <span v-if="scope.row.targetType">{{ scope.row.targetType.label }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="targetValue"
        label="目标值"
        min-width="150"
        align="center"
        slot="targetValue"
        >
        <!-- <template slot-scope="scope">
          {{ scope.row.targetType.label }}
        </template> -->
      </el-table-column>
      <el-table-column
        prop="doneValue"
        label="已完成"
        min-width="100"
        align="center"
        slot="doneValue"
      >
        <template slot-scope="scope">
          {{scope.row.doneValue?scope.row.doneValue:0}}
        </template>  
      </el-table-column>
      <el-table-column
        prop="targetUnit"
        label="计量单位"
        min-width="80"
        align="center"
        slot="targetUnit"
      ></el-table-column>
      <el-table-column
        prop="deptName"
        label="责任部门"
        min-width="150"
        align="center"
        slot="deptName"
      >
        <template slot-scope="scope">
          {{ scope.row.deptName }}
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        min-width="150"
        align="center"
        slot="createTime"
      ></el-table-column>
      <el-table-column
        prop="endTime"
        slot="endTime"
        label="截止时间"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="donePer"
        slot="donePer"
        label="完成度"
        align="center"
      >
        <template slot-scope="scope">
          {{ (scope.row.donePer * 100).toFixed(2) }}%
        </template>
      </el-table-column>
      <el-table-column
        prop="targetDescript"
        slot="targetDescript"
        label="描述"
        min-width="250"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="status"
        slot="status"
        label="目标状态"
        align="center"
      >
        <template slot-scope="scope">
          <span :class="scope.row.status.value == 2?'activeColor':''">{{scope.row.status.label }}</span>
          <!-- {{
            scope.row.status == 1
              ? "进行中"
              : scope.row.status == 2
              ? "未达标"
              : "已完成"
          }} -->
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        slot="handle"
        align="center"
        min-width="200"
      >
        <template slot-scope="scope">
          <el-button type="text" size="small" v-if="isDetail && scope.row.level == 1" @click="showDetailed(scope.row.id,1)"
            >详情</el-button
          >
          <el-button v-if="scope.row.status.value == 1 && scope.row.level == 1"  type="text" size="small" @click="showDetailed(scope.row.id,2)"
            >编辑</el-button
          >
          <!-- && ($store.state.userId == scope.row.dutyUser || $store.state.postLabelList == scope.row.deptLabel)  -->
          <!-- 新园开办的更新进度 -->
          <el-button type="text" v-if="isViewProgress && scope.row.level == 1" size="small" @click="showProgress(scope.row.id)"
            >查看进度
          </el-button>
          <!-- 
            dutyUser:[{
              postLabel:'', //userInfo.postLabel
              userLabel:['','']  //userInfo.userId
            }]
           -->
          <el-button type="text" size="small"  v-if="scope.row.status.value == 1 && (scope.row.targetType.value == 4 ||  scope.row.targetType.value == 5) && isUpdateProgress && isShow(scope.row.dutyUser) && scope.row.level == 2 "  @click="updataProgress(scope.row.id,scope.row.targetType.value)"
            >更新进度
          </el-button>
          <!-- 另一种更新进度 -->
          <el-button type="text" size="small"  v-if="scope.row.status.value == 1 && scope.row.targetType.value > 6 && scope.row.updateType == 0 && isUpdateProgress && isShow(scope.row.dutyUser) && scope.row.level == 2"  @click="updataProgress(scope.row.id,0)"
            >更新进度
          </el-button>
          <el-button type="text"
            size="small" v-if="scope.row.status.value!=1 && scope.row.level == 2 && scope.row.targetType.value != 1&& scope.row.targetType.value != 2 && scope.row.targetType.value != 6 && scope.row.updateType == 0 && isShow(scope.row.dutyUser)"
            @click="updataProgress(scope.row.id,scope.row.targetType.value,1)"
            >查看</el-button
          >
          <el-button type="text"
            size="small" v-if="isSuperviseRecord && scope.row.level == 2"
            @click="superviselog(scope.row)"
            >督办记录</el-button
          >
        </template>
      </el-table-column>
    </l-table>
    <!-- 督办记录弹窗 -->
    <el-dialog title="督办记录" :visible.sync="superviseLogPanel" width="50%">
        <el-table :data="superviseLogData" style="width: 100%">
            <el-table-column
            prop="createTime"
            align="center"
            label="日期"
            width="180"
            >
            </el-table-column>
            <el-table-column
            prop="userName"
            align="center"
            label="督办人"
            width="180"
            >
            </el-table-column>
            <el-table-column prop="comment" align="center" label="督办建议">
            </el-table-column>
        </el-table>
    </el-dialog>
  </div>
</template>

<script>

import LTable from "@/components/tableComponent/index.vue";
import {
  getPermissionButton,
  isHasPermission,
} from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";
export default {
  components: {
    LTable,
  },
  data() {
    var checkNum = (rule, value, callback) => {
      if (!value) {
        return callback();
      }
      let valueString = value + "";
      let numberAuth = /[^\d]/g;
      if (numberAuth.test(valueString)) {
        callback(new Error("只支持数字"));
      } else if (valueString.length > 1 && valueString.substr(0, 1) === "0") {
        callback(new Error("只支持数字，首位不能是0"));
      } else {
        callback();
      }
    };
    return {
      tableLoading: false, // tableloading
      page: {
        num: 1,
        size: 10,
        total: 0,
      }, // 分页信息
      pageFlag: false,
      columns: [
        {
          label: "目标名称",
          slotName: "targetName",
        },
        {
          label: "目标类型",
          slotName: "targetType",
        },
        {
          label: "目标值",
          slotName: "targetValue",
        },
        {
          label: "已完成",
          slotName: "doneValue",
        },
        {
          label: "计量单位",
          slotName: "targetUnit",
        },
        {
          label: "责任部门",
          slotName: "deptName",
        },
        {
          label: "创建时间",
          slotName: "createTime",
        },
        {
          label: "截止时间",
          slotName: "endTime",
        },
        {
          label: "完成度",
          slotName: "donePer",
        },
        {
          label: "描述",
          slotName: "targetDescript",
        },
        {
          label: "目标状态",
          slotName: "status",
        },
        {
          label: "操作",
          slotName: "handle",
        },
      ],
      title: "",
      dictList: {
        target_type: [],
        target_status: [],
      },
      departList:[],
      form: {
        targetType: null,
        targetName: null,
        deptLabel: null,
        status: null,
      },
      tableData: [],
      //   pagination: {
      //     //分页信息
      //     total: 30, // 总页数
      //     currentPage: 1, // 当前页数
      //     Size: 10, // 每页显示多少条
      //   },
      permissionButtonList: [], // 权限按钮list
      isUpdate: true,
      isDelete: true,
      isAdd: true,
      isDetail:false,
      isViewProgress:false,
      isUpdateProgress:false,
      isSuperviseRecord:false,
      isSupervise:false,
      deptProps:{
        checkStrictly: true,
        label:'name',
        value:'label',
        children:'child'
      },
      superviseLogPanel:false,
      superviseLogData:[],
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          // this.isUpdate = val.indexOf("dict:update") != -1;
          // this.isDelete = val.indexOf("dict:delete") != -1;
          this.isAdd = val.indexOf("z_target:add") != -1;
          this.isDetail = val.indexOf("z_target:detail") != -1; //详情
          this.isViewProgress = val.indexOf("z_target:viewProgress") != -1; //查看进度
          this.isUpdateProgress = val.indexOf("z_target:updateProgress") != -1; //更新进度
          this.isSuperviseRecord = val.indexOf("z_target:supervise_record") != -1; //督办记录
          this.isSupervise = val.indexOf("z_target:supervise") != -1; //督办
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            console.log(data, "右侧权限按钮按钮按钮按钮啊");
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created() {
    // this.init();
    this.queryDict();
    this.getDepartList()
    // menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      console.log(data, "右侧权限按钮按钮按钮按钮啊created");
      this.permissionButtonList = data;
    });
  },
  activated(){
    this.init();
  },
  methods: {
    getPermissionButton,
    isHasPermission,
    // 判断当前登录人和数据的关系
    isShow(dutyUser){
      if(dutyUser){
        let userList = JSON.parse(dutyUser)
        let isUser = -1
        userList.forEach(val=>{
          if(val.postLabel === this.$store.state.userInfo.postLabelList){
            isUser = val.userLabel.indexOf(this.$store.state.userInfo.userId)
          }
        })
        if(isUser!=-1){
          return true
        }else{
          return false
        }
      }else{
        return false
      }
    },
    /** 初始化 */
    init() {
      let parmas = JSON.parse(JSON.stringify(this.form))
      if (parmas.keyword == "") {
        parmas.keyword = null;
      }
      if(parmas.deptLabel){
        parmas.deptLabel = parmas.deptLabel[parmas.deptLabel.length-1]
      }
      console.log(parmas)
      this.tableLoading = true;
      this.$api
        .getTargetList(
          Object.assign(
            {
              current: this.page.num,
              size: this.page.size,
              flag:'headoffice'
            },
            parmas
          )
        )
        .then((res) => {
          this.tableLoading = false;
          if (res.data.code == "0") {
            this.tableData = res.data.data.records;
            this.page.total = res.data.data.total;
            if (res.data.data.total >= 1) {
              this.pageFlag = true;
            } else {
              this.pageFlag = false;
            }
          } else {
            this.tableData = [];
            this.page.total = 0;
            this.pageFlag = false;
            this.$message.error(res.data.msg);
          }
        });
    },
    // 查询
    searchFun() {
      this.page.num = 1
      this.init();
    },
    // 获取责任部门
    getDepartList(){
      this.$api.getDepartList().then(res=>{
        if(res.data.code == '0'){
          this.departList = res.data.data
          if(this.departList){
            this.departList.forEach((e,index)=>{
              e.disabled = true
              e.label = index
            })
          }
                // res.data.fieldName = item;
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    // 重置按钮
    resetFun() {
      this.form = {
        targetType: null,
        targetName: null,
        deptLabel: null,
        status: null,
      };
      this.page.num = 1;
      this.init();
    },
    handleAdd(){
      this.$router.push({
        path:''
      })
    },
    /** 分页 */
    handleCurrentChange(val) {
      this.page.num = val;
      this.init();
    },
    handleSizeChange(val) {
      this.page.size = val;
      this.page.num = 1;
      this.init();
    },
    showDetailed(id,type) {
      this.$router.push({
        path:'/targetDetail',
        query:{
          lookId:id,
          type:type
        }
      })
    },
    showProgress(id) {
      this.$router.push({
        path:'/showProgress',
        query:{
          id:id,
          level:1,
          flag:'headoffice'
        }
      })
    },
    updataProgress(id,type,check){
      let isCheck = null
      if(check){
        isCheck = check
        if(type>6){
          type = 0
        }
      }else{
        isCheck = 0
      }
      this.$router.push({
        path:'/uploadProgress',
        query:{
          lookId:id,
          type:type,
          isCheck:isCheck
        }
      })
    },
    queryDict() {
      var dict = Object.keys(this.dictList);
      let requests = dict.map(
        (item) =>
          new Promise((resolve, reject) => {
            this.$api
              .getDictionary({ type: item })
              .then((res) => {
                res.data.fieldName = item;
                resolve(res.data);
              })
              .catch(() => {
                resolve([]);
              });
          })
      );
      Promise.all(requests).then((res) => {
        res.forEach((item) => {
          this.dictList[item.fieldName] = item.data;
        });
      });
    },
    // 督办记录
    superviselog(row) {
      this.$api.supervisionRecordList(row.id).then((res) => {
        this.superviseLogPanel = true
        if (res.data.code == 0) {
          this.superviseLogData = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";
.dict {
  background-color: #ffffff;
  height: calc(100% - 56px);
  padding: 26px 30px 30px 30px;
  .tableList {
    height: calc(100% - 122px);
  }
}
.title{
  font-weight: bold;
  font-size: 22px;
}
</style>
